import "./App.css";
import { forwardRef, useEffect, useState } from "react";
import Product from "./components/Product";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Slide from "@mui/material/Slide";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { blue, green, orange } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Lottie from "lottie-react";
import cam from "./cam.json";
import CameraOutdoorIcon from "@mui/icons-material/CameraOutdoor";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SwissPhoneNumberRegex =
  /^(\+41|0|41)?([1-9]{2}|21)(\d{1,2}\s?){3}(\d{2}\s?){2}$/;
const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function App() {
  const [data, setData] = useState([
    {
      id: 0,
      count: 0,
      title: "Chargeur induction pour natel",
      price: 139,
      description: "Design florale unique. Réalisé en Suisse.",
      images: [
        "https://hyperspark.ch/backend/static/flowers.png",
        "https://images.unsplash.com/photo-1601887389937-0b02c26b602c?q=80&w=2454&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
    {
      id: 1,
      count: 0,
      title: "Détecteur de mouvement",
      price: 139,
      description: "Statue éclairée",
      images: [
        "https://images.unsplash.com/photo-1544609569-2e64f14008f8?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1601887389937-0b02c26b602c?q=80&w=2454&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  ]);
  const [openPay, setOpenPay] = useState(false);
  const [openPartner, setOpenPartner] = useState(false);
  const [partner, setPartner] = useState({
    email: "",
    mobile: "",
    ville: "",
    name: "",
    adresse: "",
    cp: "",
    bank: "",
    iban: "",
    note: "",
  });
  const [customer, setCustomer] = useState({
    email: "",
    mobile: "",
    ville: "",
    name: "",
    adresse: "",
    cp: "",
  });
  const [brand_code, setBrand_code] = useState("");

  const updateCount = (id, newCount) => {
    setData((data) =>
      data.map((item) => (item.id === id ? { ...item, count: newCount } : item))
    );
  };

  async function sendPay() {
    console.log({
      ...customer,
      brand_code,
    });
  }

  async function sendPartner() {
    console.log({
      ...partner,
    });
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    query.get("prtn") && setBrand_code(query.get("prtn"));

    console.log(query.get("prtn"));
  }, []);

  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
      }}>
      <div className="background-image">
        <Lottie
          animationData={cam}
          style={{ width: "15vmax", marginRight: "10%", display: "none" }}
          loop={true}
        />
      </div>

      <div
        style={{
          width: "100%",
          maxWidth: "500px",
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          padding: "2rem",
          borderRadius: "50px",
        }}>
        <h2
          style={{
            textAlign: "center",
          }}>
          HyperSpark
        </h2>

        <Button
          variant="contained"
          startIcon={<EmailIcon />}
          onClick={() => {
            if (window.confirm("Voulez-vous ouvrir votre boîte mail ?")) {
              window.location.href =
                "mailto:office@hyperspark.ch?subject=Demande%20de%20contact%20HyperSpark"; // Remplacez par votre adresse e-mail
            }
          }}
          sx={{
            borderRadius: "15px",
            margin: "1rem 0",
            padding: "0.5rem 1.5rem",
            fontWeight: "600",
            textDecoration: "none",
          }}>
          office@hyperspark.ch
        </Button>
      </div>

      <p style={{ padding: "2rem 1rem", fontWeight: 500, fontSize: "14px" }}>
        * 2024 🛠️ site en construction *
      </p>

      {data.map((dat) => dat.count).reduce((a, b) => a + b, 0) > 0 && (
        <Button
          variant="contained"
          onClick={() => setOpenPay(true)}
          style={{
            zIndex: 5,
            width: "90%",
            boxShadow: "0 0 20px 0 rgba(0,0,0,0.5)",
            position: "fixed",
            bottom: 10,
            color: "black",
            left: "50%",
            transform: "translateX(-50%)",
            margin: "1rem 0",
            borderRadius: "50px",
            padding: "0.7rem",
            backgroundColor: "#facc6b",
            backgroundImage: "linear-gradient(315deg, #facc6b 0%, #fabc3c 74%)",
            fontWeight: "800",
            fontSize: "18px",
          }}>
          Voir le panier{" "}
          <div
            style={{
              background: "red",
              borderRadius: "50%",
              color: "white",
              padding: "0.25rem 0.5rem",
              marginLeft: "1rem",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.8rem",
              height: "1.5rem",
              minWidth: "1.5rem",
              textAlign: "center",
            }}>
            {data.map((dat) => dat.count).reduce((a, b) => a + b, 0)}
          </div>
        </Button>
      )}

      <Dialog
        TransitionComponent={Transition}
        fullScreen
        scroll="paper"
        open={openPay}
        onClose={() => setOpenPay(false)}>
        <div
          style={{
            padding: "1rem",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "solid 2px rgb(230,230,230)",
          }}>
          <h3>Vérifiez votre commande</h3>

          <Button
            variant="contained"
            sx={{
              borderRadius: "50px",
              padding: "0.5rem 2rem",
              fontWeight: "600",
            }}
            onClick={() => setOpenPay(false)}>
            Retour
          </Button>
        </div>

        <DialogContent sx={{ padding: 0, maxWidth: 900, margin: "0 auto" }}>
          <div style={{ padding: "2rem 1rem" }}>
            <h4 style={{ marginBottom: "1rem" }}>Commande</h4>

            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "2rem",
                    }}></th>
                  <th></th>
                  <th style={{ width: "5rem" }}></th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter((item) => item.count > 0)
                  .map((item) => (
                    <tr key={item.id}>
                      <td>{item.count}</td>
                      <td>{item.title}</td>
                      <td style={{ textAlign: "right" }}>{item.price}</td>
                    </tr>
                  ))}
                <tr>
                  <td>📦</td>
                  <td>Livraison</td>
                  <td style={{ textAlign: "right" }}>OFFERT</td>
                </tr>
              </tbody>
            </table>

            <Divider sx={{ margin: "1rem 0" }} />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}>
              <h4>TOTAL</h4>
              <h4>
                {data
                  .filter((item) => item.count > 0)
                  .map((calc) => calc.price * calc.count)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}
              </h4>
            </div>
          </div>

          <div
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}>
            <h4 style={{ marginBottom: "1rem" }}>Information de livraison</h4>

            <TextField
              label="Nom et Prénom"
              type="text"
              value={customer.name}
              onChange={(e) =>
                setCustomer({ ...customer, name: e.target.value })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Adresse"
              type="text"
              value={customer.adresse}
              onChange={(e) =>
                setCustomer({ ...customer, adresse: e.target.value })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Code Postal"
              type="text"
              value={customer.cp}
              onChange={(e) =>
                setCustomer({ ...customer, cp: e.target.value.trim() })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Ville (Suisse🇨🇭)"
              type="text"
              value={customer.ville}
              onChange={(e) =>
                setCustomer({ ...customer, ville: e.target.value })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Email"
              error={!EmailRegex.test(customer.email)}
              type="email"
              value={customer.email}
              onChange={(e) =>
                setCustomer({ ...customer, email: e.target.value.trim() })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              error={!SwissPhoneNumberRegex.test(customer.mobile)}
              label="Mobile"
              type="tel"
              fullWidth
              variant="outlined"
              value={customer.mobile}
              onChange={(e) =>
                setCustomer({ ...customer, mobile: e.target.value.trim() })
              }
            />

            <p style={{ opacity: "0.5", fontSize: "14px" }}>
              * En cliquant sur "Payer", vous acceptez nos conditions générales,
              qui régissent votre utilisation de notre service.
            </p>

            <Button
              disabled={
                !(
                  SwissPhoneNumberRegex.test(customer.mobile) &&
                  EmailRegex.test(customer.email) &&
                  customer.name.trim().length > 0 &&
                  customer.adresse.trim().length > 0 &&
                  customer.cp.trim().length > 0 &&
                  customer.ville.trim().length > 0
                )
              }
              variant="contained"
              onClick={sendPay}
              fullWidth
              sx={{
                padding: "0.5rem 2rem",
                fontWeight: "800",
                fontSize: "16px",
                color: "black",
                backgroundColor:
                  SwissPhoneNumberRegex.test(customer.mobile) &&
                  EmailRegex.test(customer.email) &&
                  customer.name.trim().length > 0 &&
                  customer.adresse.trim().length > 0 &&
                  customer.cp.trim().length > 0 &&
                  customer.ville.trim().length > 0 &&
                  "#facc6b",
                backgroundImage:
                  SwissPhoneNumberRegex.test(customer.mobile) &&
                  EmailRegex.test(customer.email) &&
                  customer.name.trim().length > 0 &&
                  customer.adresse.trim().length > 0 &&
                  customer.cp.trim().length > 0 &&
                  customer.ville.trim().length > 0 &&
                  "linear-gradient(315deg, #facc6b 0%, #fabc3c 74%)",
              }}>
              PAYER CHF{" "}
              {data
                .filter((item) => item.count > 0)
                .map((calc) => calc.price * calc.count)
                .reduce((a, b) => a + b, 0)
                .toFixed(2)}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        TransitionComponent={Transition}
        fullScreen
        scroll="paper"
        open={openPartner}
        onClose={() => setOpenPartner(false)}>
        <div
          style={{
            padding: "1rem",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "solid 2px rgb(230,230,230)",
          }}>
          <h3>🚀 Vendre des produits HyperSpark</h3>

          <Button
            variant="contained"
            sx={{
              borderRadius: "50px",
              padding: "0.5rem 2rem",
              fontWeight: "600",
            }}
            onClick={() => setOpenPartner(false)}>
            Retour
          </Button>
        </div>

        <DialogContent sx={{ padding: 0, maxWidth: 900, margin: "0 auto" }}>
          <div
            style={{
              padding: "2rem 1rem",
              textAlign: "center",
            }}>
            <h4 style={{ marginBottom: "1rem" }}>
              Vous souhaitez utiliser vos canaux de ventes pour promouvoir et
              vendre des produits HyperSpark?
            </h4>
            <p>
              Nous vous offrons un code promo personnalisé ainsi que 15% de
              comission sur vos ventes.
            </p>
          </div>

          <div
            style={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}>
            <h4 style={{ marginBottom: "1rem" }}>Vos informations</h4>

            <TextField
              label="Nom et Prénom"
              type="text"
              value={partner.name}
              onChange={(e) => setPartner({ ...partner, name: e.target.value })}
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Adresse"
              type="text"
              value={partner.adresse}
              onChange={(e) =>
                setPartner({ ...partner, adresse: e.target.value })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Code Postal"
              type="text"
              value={partner.cp}
              onChange={(e) =>
                setPartner({ ...partner, cp: e.target.value.trim() })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Ville (Suisse 🇨🇭)"
              type="text"
              value={partner.ville}
              onChange={(e) =>
                setPartner({ ...partner, ville: e.target.value })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Banque"
              type="text"
              value={partner.bank}
              onChange={(e) =>
                setPartner({ ...partner, bank: e.target.value.trim() })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="IBAN"
              type="text"
              value={partner.iban}
              onChange={(e) =>
                setPartner({ ...partner, iban: e.target.value.trim() })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Email"
              error={!EmailRegex.test(partner.email)}
              type="email"
              value={partner.email}
              onChange={(e) =>
                setPartner({ ...partner, email: e.target.value.trim() })
              }
              fullWidth
              variant="outlined"
            />

            <TextField
              error={!SwissPhoneNumberRegex.test(partner.mobile)}
              label="Mobile"
              type="tel"
              fullWidth
              variant="outlined"
              value={partner.mobile}
              onChange={(e) =>
                setPartner({ ...partner, mobile: e.target.value.trim() })
              }
            />

            <p style={{ opacity: "0.5", fontSize: "14px" }}>
              * En vous inscrivant pour vendre des produits HyperSpark, vous
              acceptez nos conditions générales, qui régissent votre utilisation
              de notre service.
            </p>

            <Button
              disabled={
                !(
                  SwissPhoneNumberRegex.test(partner.mobile) &&
                  EmailRegex.test(partner.email) &&
                  partner.name.trim().length > 0 &&
                  partner.adresse.trim().length > 0 &&
                  partner.cp.trim().length > 0 &&
                  partner.ville.trim().length > 0 &&
                  partner.iban.trim().length > 0 &&
                  partner.bank.trim().length > 0
                )
              }
              variant="contained"
              onClick={sendPartner}
              fullWidth
              sx={{
                padding: "0.5rem 2rem",
                fontWeight: "800",
                fontSize: "16px",
              }}>
              Devenir partenaire
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
